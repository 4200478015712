import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", { attrs: { "visible": _vm.visible, "title": _vm.importDetail ? "Import Details" : "Import Standard Speed", "after-close": _vm.handleCancel, "destroy-on-close": true }, on: { "cancel": _vm.handleCancel }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
    return [!_vm.importDetail ? _c("div", [_c("a-button", { on: { "click": _vm.handleCancel } }, [_vm._v(" Cancel ")]), _c("a-button", { attrs: { "type": "primary", "disabled": !_vm.files.length > 0, "loading": _vm.isImporting }, on: { "click": _vm.onImport } }, [_vm._v(" Import ")])], 1) : _vm._e()];
  }, proxy: true }]) }, [!_vm.importDetail ? _c("div", { staticClass: "import-form" }, [_c("p", { staticClass: "title-upload" }, [_vm._v(" Download the SKU Standard Speed import template ")]), _c("div", { staticClass: "template-file" }, [_c("a-row", { attrs: { "gutter": 24, "type": "flex", "justify": "space-between" } }, [_c("a-col", [_c("a-icon", { attrs: { "type": "file-zip", "theme": "filled" } }), _c("a", { attrs: { "href": _vm.apiUrl + "/files/assets/SKU-Standard-Speed-Import-Template.xlsx", "download": "" } }, [_vm._v(" SKU-Standard-Speed-Import-Template.xlsx ")])], 1), _c("a-col", { staticStyle: { "text-align": "right" } }, [_c("a-icon", { attrs: { "type": "cloud-download" } })], 1)], 1)], 1), _c("div", { staticClass: "my-5" }, [_c("a-upload-dragger", { attrs: { "name": "file", "accept": ".xlsx", "show-upload-list": false, "disabled": _vm.files.length > 0, "before-upload": _vm.onBeforeUpload } }, [_c("p", { staticClass: "ant-upload-drag-icon" }, [_c("a-icon", { attrs: { "type": "inbox" } })], 1), _c("p", { staticClass: "ant-upload-text" }, [_c("span", [_vm._v("Click here")]), _vm._v(" or drag file to this area to upload ")]), _c("p", { staticClass: "ant-upload-hint" }, [_vm._v(" Single upload file should not be more than 10MB. Support only .xlsx file. ")])])], 1), _vm.files.length ? _c("div", { staticClass: "files-imported-container" }, [_c("p", { staticClass: "title-upload" }, [_vm._v("Import files")]), _c("ul", { staticClass: "files" }, _vm._l(_vm.files, function(file, index) {
    return _c("li", { key: index }, [_c("a-icon", { attrs: { "type": "file-excel" } }), _c("a", [_vm._v(_vm._s(file.name))]), _c("a-icon", { attrs: { "type": "close" }, on: { "click": function($event) {
      return _vm.files.splice(index, 1);
    } } })], 1);
  }), 0)]) : _vm._e()]) : _c("div", { staticClass: "import-detail" }, [_c("h3", { staticClass: "mb-0" }, [_vm._v("Import Standard Speed")]), _vm.importDetail.createdBy ? _c("p", { staticClass: "modified-by" }, [_vm._v(" " + _vm._s(this.$moment(_vm.importDetail.createdAt).format("DD/MM/YYYY hh:mm")) + " by " + _vm._s(_vm.importDetail.createdBy) + " ")]) : _vm._e(), _c("div", { staticClass: "file-name d-flex justify-content-between" }, [_c("h4", { staticClass: "mb-0" }, [_vm._v("File: " + _vm._s(_vm.importDetail.fileName))]), _vm.importDetail.status === "Error" ? _c("p", [_vm._v(" Failed: " + _vm._s(_vm.importDetail.totalError) + "/" + _vm._s(_vm.importDetail.totalRecordFromFile) + " ")]) : _vm.importDetail.status === "Successful" ? _c("p", { staticClass: "mb-0" }, [_c("a-icon", { attrs: { "type": "check-circle", "theme": "twoTone", "two-tone-color": "#52c41a" } }), _vm._v(" " + _vm._s(_vm.importDetail.status) + " ")], 1) : _vm._e()]), _c("ul", { staticClass: "errors pl-0" }, _vm._l(_vm.importDetail.errorInfos, function(error) {
    return _c("li", { key: error.index }, [_vm._v(" " + _vm._s(error.status) + " ")]);
  }), 0)])]);
};
var staticRenderFns = [];
var ImportStandardSpeed_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".import-form .anticon-file-zip[data-v-b46ddef8],.import-form .anticon-cloud-download[data-v-b46ddef8]{color:#92a0b0;font-size:20px}.import-form .template-file[data-v-b46ddef8]{padding:10px;border:1px solid #eaedf0;box-sizing:border-box;border-radius:2px}.import-form .drag-template .ant-upload-text[data-v-b46ddef8]{font-size:14px;margin-bottom:4px}.import-form .drag-template .ant-upload-text span[data-v-b46ddef8]{color:#0062ff}.import-form .drag-template .ant-upload-hint[data-v-b46ddef8]{font-size:14px}.import-form .files-container .ant-row-flex[data-v-b46ddef8]{margin-bottom:4px}.import-form .files-container .ant-row-flex a[data-v-b46ddef8]{flex-grow:1;margin-left:10px}.import-form .files-imported-container .title-upload[data-v-b46ddef8]{color:#475362;margin-bottom:8px}.import-form .files-imported-container .files[data-v-b46ddef8]{padding-left:0;margin-bottom:0;list-style:none}.import-form .files-imported-container .files li[data-v-b46ddef8]{display:flex;justify-content:space-between;align-items:center;padding-left:4px}.import-form .files-imported-container .files li a[data-v-b46ddef8]{flex-grow:1;margin-left:10px}.import-detail .modified-by[data-v-b46ddef8]{font-size:14px;color:#5c6c7f}.import-detail ul[data-v-b46ddef8]{list-style:none}.import-detail .errors li[data-v-b46ddef8]{font-size:14px;color:#f5222d}\n")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "ImportStandardSpeed",
  inject: ["crud"],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      apiUrl,
      files: [],
      importDetail: void 0,
      isImporting: false
    };
  },
  methods: {
    handleCancel() {
      if (!this.isImporting) {
        this.$emit("update:visible", false);
        setTimeout(() => {
          this.files = [];
          this.importDetail = void 0;
        }, 200);
      } else {
        this.$notification.warning({
          message: "Upload file is being processed!"
        });
      }
    },
    onBeforeUpload(file) {
      this.files = [file];
      return false;
    },
    onImport() {
      this.isImporting = true;
      const formData = new FormData();
      this.files.forEach((file) => {
        formData.append("files", file);
      });
      this.axios.post(`${this.apiUrl}/line-performance/product-masters/upload-detail`, formData).then((res) => {
        this.importDetail = res.data;
      }).finally(() => {
        this.isImporting = false;
        this.crud.fetchList();
      });
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "b46ddef8", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var ImportStandardSpeed = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { ImportStandardSpeed as default };
