import ImportStandardSpeed from "./ImportStandardSpeed.js";
import { MAINTAIN_PERMISSION, LINE_PERFORMANCE_PRODUCT_STANDARDS } from "@kraftheinz/common";
import { q as queryString } from "./index6.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-product-standards" }, [_c("list-composer", { attrs: { "title": "Product Standards", "search-by": "Code,Description", "ph-search-by": "Search by Item Code", "scroll": { x: 1200 }, "fetch-on-first-mount": false }, scopedSlots: _vm._u([{ key: "custom-buttons", fn: function() {
    return [_c("a-row", { attrs: { "type": "flex", "justify": "end", "gutter": [16, 16] } }, [_c("a-col", [_c("a-button", { attrs: { "loading": _vm.isExporting, "type": "primary" }, on: { "click": _vm.onExport } }, [_vm._v(" Export ")])], 1), _c("a-col", [_vm.hasMaintainPermission ? _c("a-button", { attrs: { "icon": "upload" }, on: { "click": function() {
      return _vm.visibleImportForm = true;
    } } }, [_vm._v(" Import Standard Speed ")]) : _vm._e()], 1), _c("a-col", [_vm.hasMaintainPermission ? _c("a-button", { attrs: { "type": "primary" }, on: { "click": function($event) {
      return _vm.$router.push("/line-performance/product-standards/create");
    } } }, [_vm._v(" Add Product Standards ")]) : _vm._e()], 1)], 1)];
  }, proxy: true }, _vm.hasMaintainPermission ? { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_c("div", { staticClass: "d-flex" }, [_c("a-tooltip", { attrs: { "destroy-tooltip-on-hide": true }, scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { class: {
      "mr-1": record.isInactive,
      "mr-2": !record.isInactive
    }, attrs: { "disabled": record.isInactive ? true : false, "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active mr-2", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1)], 1)];
  } } : null], null, true) }, [_c("url-field", { key: "Code", attrs: { "base-url": "/line-performance/product-standards", "clickable": false, "data-index": "code", "title": "Item Code", "sorter": true } }), _c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Description" } }), _c("text-field", { key: "NetWeight", attrs: { "data-index": "netWeight", "title": "Net Weight", "sorter": true } }), _c("text-field", { key: "StandardCPM", attrs: { "data-index": "standardCPM", "title": "Standard Speed", "sorter": true } }), _c("text-field", { key: "BottleneckCPM", attrs: { "data-index": "bottleneckCPM", "title": "Bottleneck", "sorter": true } }), _c("text-field", { key: "UnitUOM", attrs: { "data-index": "unitUOM", "title": "Unit / Pack", "sorter": true } }), _c("text-field", { key: "ProductionLineCode", attrs: { "data-index": "productionLineCode", "title": "Production Line", "sorter": true } })], 1), _c("import-standard-speed", { attrs: { "visible": _vm.visibleImportForm }, on: { "update:visible": function($event) {
    _vm.visibleImportForm = $event;
  } } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListProductStandard",
  components: { ImportStandardSpeed },
  inject: ["crud", "resourceName", "facility", "createRoute", "getArea", "can"],
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#",
      isLoading: false,
      visibleImportForm: false,
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      isExporting: false
    };
  },
  computed: {
    area() {
      return this.getArea();
    },
    hasMaintainPermission() {
      const { maintain } = this.permissions;
      return this.can(maintain);
    }
  },
  watch: {
    area(newVal) {
      this.crud.setFilter("AreaId", {
        operator: "Eq",
        value: newVal ? newVal : -1
      });
      this.fetchList();
    }
  },
  created() {
    this.crud.setFilter("AreaId", {
      operator: "Eq",
      value: this.area ? this.area : -1
    });
    if (this.area != 999999) {
      this.fetchList();
    }
  },
  methods: {
    storeValue(resourceKey, value) {
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    fetchList() {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      this.crud.fetchList();
    },
    async onExport() {
      this.isExporting = true;
      const filterString = this.crud.getFilterQuery();
      const queryString$1 = this.crud.getQueryStrings();
      const params = queryString.parse(`${filterString}&${queryString$1}`);
      try {
        const { data, headers, status } = await this.axios.get(`${this.apiUrl}/line-performance/product-masters/export/`, {
          params,
          responseType: "blob"
        });
        const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", objectUrl);
        link.setAttribute("download", headers.filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        const { message } = JSON.parse(await error.response.data.text());
        this.$notification.error({
          message
        });
      } finally {
        this.isExporting = false;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListProductStandards = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "product-standards-list" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "line-performance.common.local-facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.product-masters", "api-url": _vm.apiUrl, "edit-route": "/line-performance/product-standards/:id", "page": _vm.page } }, [_c("list-product-standards")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "ProductStandards",
  components: {
    ListProductStandards
  },
  data() {
    return {
      page: LINE_PERFORMANCE_PRODUCT_STANDARDS,
      apiUrl,
      ListProductStandards,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "list-product-standards",
          title: "Product Standards",
          path: "/line-performance/product-standards"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
